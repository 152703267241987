<template>
  <p>Edit this event</p>
</template>

<script>
export default {
  name: "EventEdit",
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
