<template>
  <div class="home">
    <h1>Events For Good</h1>
    <div class="events-container">
      <event-card v-for="event in events" :key="event.id" :event="event" />
      <div class="pagination">
        <router-link
          id="page-prev"
          :to="{ name: 'EventList', query: { page: page - 1 } }"
          v-if="events && page > 1"
          >&#60;&#60; Prev
        </router-link>
        <router-link
          id="page-next"
          :to="{ name: 'EventList', query: { page: page + 1 } }"
          v-if="events && hasNextPage(page)"
          >Next &#62;&#62;
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import EventCard from "@/components/EventCard.vue";
import EventsService from "@/services/events-service";

export default {
  name: "EventList",
  components: {
    EventCard,
  },
  props: {
    page: {
      type: Number,
    },
  },
  data() {
    return {
      events: [],
      totalEvents: null,
    };
  },
  //here we DON'T have access to "this"
  beforeRouteEnter(routeTo, routeFrom, next) {
    EventsService.getEvents(2, parseInt(routeTo.query.page) || 1)
      .then((response) => {
        //doesn't needs return above because next() tells router to wait until API call returns
        next((comp) => {
          comp.events = response.data;
          comp.totalEvents = response.headers["x-total-count"];
        });
      })
      .catch(() => {
        next({ name: "NetworkError" });
      });
  },
  //here we have access to "this"
  //we need this hook for pagination to works, due to use the same component for each pagination page request
  beforeRouteUpdate(routeTo) {
    return EventsService.getEvents(2, routeTo.query.page)
      .then((response) => {
        this.events = response.data;
        this.totalEvents = response.headers["x-total-count"];
      })
      .catch((error) => {
        console.error(error);
        return { name: "NetworkError" };
      })
  },
  methods: {
    hasNextPage(page) {
      return page < Math.ceil(this.totalEvents / 2);
    },
  },
};
</script>

<style scoped>
.events-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pagination {
  display: flex;
  width: 290px;
}

.pagination a {
  flex: 1;
  text-decoration: none;
  color: #2c3e50;
}

#page-prev {
  text-align: left;
}

#page-next {
  text-align: right;
}
</style>
